import { toTheme } from "@theme-ui/typography"
import noriegaTheme from "typography-theme-noriega"
import merge from "deepmerge"

export default merge(toTheme(noriegaTheme), {
  breakpoints: ["576px", "768px", "992px", "1200px"],

  colors: {
    background: "#fff",
    text: "#000",
    brandGreen: "#05b615",
    brandGreenLight: "#05b615",
    brandYellow: "#f2b212",
    brandOrange: "#FA8733",
    brandRed: "#e11634",
    brandRedLight: "#ed4266",
    brandBlue: "#0071BC",
    bgDark: "#000000",
    bgLight: "#F2F2F2",
    textDark: "#000",
    textLight: "#fff",
    borderDark: "#ccc",
  },
  text: {
    hero: {
      fontSize: ["1.5rem", "2.5rem"],
    },
    primary: {
      fontSize: ["1.2rem", "1.5rem"],
    },
    secondary: {
      fontSize: "1.2rem",
    },
    body: {
      fontSize: ["1rem", "1.125rem"],
    },
    small: {
      fontSize: ["0.75rem", "0.875rem"],
    },
  },
  buttons: {
    primary: {
      default: {
        borderRadius: "8px",
        py: "16px",
        px: "32px",
        fontWeight: "bold",
        color: "textLight",
        bg: "brandRed",
        "&:hover": {
          bg: "brandRedLight",
        },
      },
      active: {
        borderRadius: "8px",
        py: "16px",
        px: "32px",
        fontWeight: "bold",
        color: "textLight",
        bg: "brandRedLight",
        "&:hover": {
          bg: "brandRed",
        },
      },
    },
    secondary: {
      default: {
        borderRadius: "8px",
        py: "8px",
        px: "16px",
        fontWeight: "bold",
        color: "textLight",
        bg: "brandGreen",
        "&:hover": {
          bg: "brandGreenLight",
        },
      },
      active: {
        borderRadius: "8px",
        py: "8px",
        px: "16px",
        fontWeight: "bold",
        color: "textLight",
        bg: "brandGreenLight",
        "&:hover": {
          bg: "brandGreen",
        },
      },
    },
    outline: {
      default: {
        borderRadius: "8px",
        py: "8px",
        px: "16px",
        fontWeight: "bold",
        color: "brandGreen",
        bg: "transparent",
        border: "2px solid #05b615",
        "&:hover": {
          color: "textLight",
          border: "2px solid #05b615",
          bg: "brandGreenLight",
        },
      },
      active: {
        borderRadius: "8px",
        py: "8px",
        px: "16px",
        fontWeight: "bold",
        color: "textLight",
        bg: "brandGreenLight",
        "&:hover": {
          bg: "brandGreen",
        },
      },
    },
    link: {
      default: {
        borderRadius: "0px",
        py: "0px",
        px: "0px",
        lineHeight: 1,
        fontWeight: "normal",
        color: "brandGreen",
        bg: "transparent",
        borderBottom: "0px solid #05b615",
        "&:hover": {
          borderBottom: "1px solid #05b615",
        },
      },
      small: {
        borderRadius: "0px",
        py: "0px",
        px: "0px",
        lineHeight: 1,
        fontWeight: "normal",
        color: "#000",
        bg: "transparent",
        fontSize: "14px",
      },
    },
  },
  forms: {
    input: {
      py: "8px",
      background: "#fff",
      borderColor: "borderDark",
    },
    textarea: {
      py: "8px",
      background: "#fff",
      borderColor: "borderDark",
    },
    label: {
      fontWeight: "600",
      fontSize: "1rem",
    },
  },
  space: [
    0,
    8,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    72,
    80,
    88,
    96,
    104,
    112,
    120,
    128,
    136,
    144,
    152,
    160,
    168,
    176,
    184,
    192,
    200,
    208,
    216,
    224,
    232,
    240,
    248,
    256,
  ],

  opacity: {
    none: 0,
    faint: 0.3,
    medium: 0.6,
    full: 1,
  },
})
